.video-modal-container {
    position: fixed;
    top: 135px;
    left: 0;
    width: 100%;
    height: calc(100% - 135px);
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .video-modal {
    background: #fff;
    max-width: 90%;
    padding: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .video-modal video {
    width: 100%;
    max-height: 100%;
  }
  
  /* .video-modal-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  } */
  

  .video-modal-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #fff; /* Background color of the button */
    border: 2px solid #333; /* Border color */
    border-radius: 50%; /* Create a circle */
    /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%); */
    z-index: 1;

    transform: translate(75%, -75%);
  }
  
  .video-modal-container .close-button::before,
  .video-modal-container .close-button::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #333; /* Color of the close icon lines */
    transition: background-color 0.3s;

    left:12px;
    top:3px;
  }
  
  .video-modal-container .close-button::before {
    transform: rotate(45deg);
  }
  
  .video-modal-container .close-button::after {
    transform: rotate(-45deg);
  }
  
  /* Hover effect for close button */
  .video-modal-container .close-button:hover::before,
  .video-modal-container .close-button:hover::after {
    background-color: #ff2600; /* Hover color */
  }