.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 99999;
}

.modal-content {
  background-color: #fff;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;

  display: flex;
  justify-content: right;
  align-items: flex-end;
  flex-direction: column;

  
}



/* Close button styles */
.modal-overlay .close-button {
    position: absolute;
    /* top: 10px; */
    /* right: 10px; */
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: #fff; /* Background color of the button */
  border: 2px solid #333; /* Border color */
  border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translate(75%, -75%);
    
  }
  
  .modal-overlay .close-button::before,
  .modal-overlay .close-button::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #333; /* Color of the close icon lines */
    transition: background-color 0.3s;
  }
  
  .modal-overlay .close-button::before {
    transform: rotate(45deg);
  }
  
  .modal-overlay .close-button::after {
    transform: rotate(-45deg);
  }
  
  /* Hover effect for close button */
  .modal-overlay .close-button:hover::before,
  .modal-overlay .close-button:hover::after {
    background-color: #ff2600; /* Hover color */
  }