.header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  font-family: 'PoiretOne-Regular';
  font-size: 18px;
  padding: 0px 60px 0px 60px;
  background-color: #fff;
  color: #231f20;
  box-shadow: 0px 0px 15px -0px #999;
  z-index: 99999;
  position: sticky;
  top: 0;
  height: 135px;
}
.header a{
  all: unset;
}
.title{
  all: unset;
  font-weight: lighter;
  font-size: 54px;
  cursor: pointer;
}
.logo
{
  margin-top: 5px;
  cursor: pointer;
}
.logo img {
  height: 30px;
}

.navLinks {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navLinks li a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.navLinks li a:hover {
  color: #ccc;
}
@media (max-width: 830px) {
  .title{
    font-size: 44px;
  }
}
@media (max-width: 730px) {
  .title{
    font-size: 38px;
  }
  .logo img {
    height: 20px;
  }
}
@media (max-width: 690px) {
  .header {
    flex-direction: column-reverse;
    padding: 10px 20px 10px 20px;
    height: auto;
  }
  .title{
    font-size: 38px;
    margin: 10px;
  }
  .logo {
/* margin-top: 0px; */
  }
}

@media (max-width: 550px) {
  .header {
    font-size: 14px;
    padding: 10px 20px 10px 20px;
  }
  .title{
    font-size: 34px;
    margin: 0;
  }
  .logo img {
    height: 20px;
  }
}

@media (max-width: 360px) {
  .header {
    font-size: 13px;
    padding: 10px 10px 10px 10px;
  }
  
  .logo img {
    height: 16px;
  }
}