/* .categoryPage {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
} */

.categoryPage {
  max-width: 100%;
  margin: 0 auto;
  /* padding: 40px; */
  display: flex;
  align-items: flex-start;
  /* flex-direction: column; */
}

/* .menu {
  flex: 0 0 250px;
} */

.menu_bar {
  display: none;
}

.menu {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  margin-right: 40px;
  /* opacity: 0; */
  flex: 0 0 250px;
  background-color: #fff;
  height: 100vh;
  min-height: 550px;
  border-right: 1px solid #ccc;
  user-select: none;
  height: calc(100vh - 135px);
  /* transform: translateY(-50px); */
  /* animation: menuAnimation 0.5s ease-in-out forwards; */

  /* opacity: 0; */
  /* transform: translateY(-50px); */
  /* animation: menuAnimation 0.5s ease-in-out forwards; */
}

.showMenu {
  /* opacity: 0;
  transform: translateY(-50px);
  animation: menuAnimation 0.5s ease-in-out forwards; */
}

@keyframes menuAnimation {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  50% {
    opacity: 0;
    /* transform: translateY(-50px); */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.menuVisible {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.menu a {
  all: unset;
}

.menuItem {
  padding: 20px;
  border-bottom: 1px solid #333333;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;
  /* transform: translateY(-20px);
  opacity: 0; */
  /* animation: menuItemAnimation 0.3s ease-in-out forwards; */
}
/*
.menuItem:nth-child(1) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.1s;
}

.menuItem:nth-child(2) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.2s;
}

.menuItem:nth-child(3) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.3s;
}

.menuItem:nth-child(4) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.4s;
}

.menuItem:nth-child(5) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.5s;
}

.menuItem:nth-child(6) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.6s;
}

.menuItem:nth-child(7) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.7s;
}

.menuItem:nth-child(8) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.8s;
}

.menuItem:nth-child(9) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.9s;
}

.menuItem:nth-child(10) {
  animation: menuItemAnimation 0.3s ease-in-out forwards 0.10s;
}

 Add more nth-child rules for additional menu items */

@keyframes menuItemAnimation {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.menuItem:hover {
  background-color: #ffeed8;
}

.selected {
  background-color: #ccc !important;
}

.content {
  display: block;
  width: 100%;
  height: calc(100vh - 250px);
  padding: 60px 60px;
  overflow: auto;
}

.title {
  font-size: 36px;
  margin-bottom: 40px;
}

.optionList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* height: 100%; */
  width: 100%;
  gap: 60px;
  place-items: center;
  color: #666;
  /* margin-bottom: 100px; */
}

.optionList.contacts_page {
  place-items: start;
}

.optionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 250px;
  max-width: 250px;
  height: 230px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.4);
  opacity: 0;
  animation: fadeIn 0.2s forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.optionCard.fadeIn {
  /* opacity: 1; */
}

.optionCard:hover {
  background-color: #ffeed8;
}

.optionCard img {
  width: 220px;
  /* height: 200px; */
  /* margin-bottom: 10px; */
  border: 1px solid #ccc;
  margin-top: 15px;
}

.icon_label_container {
  height: 60px;
  position: relative;
  bottom: 0px;
  display: flex;
  align-items: center;
}

.icon_image {
  width: 228px;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.label {
  font-size: 1.2rem;
  margin-top: 10px;
  font-weight: lighter;
  text-align: center;

  color: #333333;
  width: 228px;
}

/* .optionCard h2 {
  font-size: 18px;
  color: #333;
} */

.contact_container {
  display: block;
  position: fixed;
  bottom: 0px;
  width: 250px;
}

.contact {
  padding: 20px;
  border-top: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
}
.contact:hover {
  background-color: #ffeed8;
}

.contact_sec {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #333333;
  padding: 10px 0;
  color: #333333;
  /* height: 40px; */
}

.contact_title {
  align-items: start;
  flex-direction: column;
}

.contact_title .vp_name {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 5px;
  color: #000000;
}
.contact_sec {
  cursor: default;
}
.contact_sec a {
  all: unset;
  cursor: pointer;
  color: #0545ad;
}

.contact_sec a:hover {
  text-decoration: underline;
}

.contact_icon {
  display: inline-block;
  width: 41px;
  height: 51px;
  margin-right: 10px;
  background-repeat: no-repeat;
}
.contact_tel .contact_icon {
  background-position: 0px 7px;
}
.contact_email .contact_icon {
  background-position: 0px 15px;
}
.contact_loc .contact_icon {
  background-position: 0px 0px;
}
.contact_url .contact_icon {
  background-position: 0px 9px;
}

.contact_url {
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 830px) {
  .contact_container {
    display: block;
    position: fixed;
    /* bottom: 88px; */
    width: 250px;
  }
}
@media (max-width: 690px) {
  .header {
    flex-direction: column;
  }
  .content {
    top: 50px;
    position: relative;
    height: calc(100vh - 270px);
    padding-left: 0px;
  }
  .categoryPage {
    flex-direction: column;
  }
  .menu_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #ccc;
    /* padding: 0 20px 0 20px; */
  }

  .category_title {
    margin-left: 20px;
  }
  .category_menu_icon {
    margin-right: 20px;
  }
  .menu {
    position: absolute;
    height: auto;
    width: 100%;
    display: none;
  }
  .showMenu {
    display: flex;
    margin-top: 50px;
    z-index: 99999;
    background-color: transparent;
  }
  .categoryPage {
    /* padding: 20px; */
  }

  .optionList {
    grid-template-columns: 1fr;
  }
  .optionList.contacts_page {
    place-items: center;
  }

  .contact_container {
    /* display: none; */
    bottom: auto;
    position: relative;
    width: auto;
    border-bottom: 1px solid #333333;
    opacity: 0;
    transform: translateY(-20px);
    background-color: #fff;
    animation: menuItemAnimation 0.8s ease-in-out forwards 1s;
  }

  .menuItem {
    transform: translateY(-20px);
    opacity: 0;
    background-color: #fff;
    animation: menuItemAnimation 0.1s ease-in-out forwards;
  }

  .menuItem:nth-child(1) {
    animation: menuItemAnimation 0.1s ease-in-out forwards 0.2s;
  }

  .menuItem:nth-child(2) {
    animation: menuItemAnimation 0.2s ease-in-out forwards 0.3s;
  }

  .menuItem:nth-child(3) {
    animation: menuItemAnimation 0.3s ease-in-out forwards 0.4s;
  }

  .menuItem:nth-child(4) {
    animation: menuItemAnimation 0.4s ease-in-out forwards 0.5s;
  }

  .menuItem:nth-child(5) {
    animation: menuItemAnimation 0.5s ease-in-out forwards 0.6s;
  }

  .menuItem:nth-child(6) {
    animation: menuItemAnimation 0.6s ease-in-out forwards 0.7s;
  }

  .menuItem:nth-child(7) {
    animation: menuItemAnimation 0.7s ease-in-out forwards 0.8s;
  }

  .menuItem:nth-child(8) {
    animation: menuItemAnimation 0.8s ease-in-out forwards 0.9s;
  }

  .menuItem:nth-child(9) {
    animation: menuItemAnimation 0.9s ease-in-out forwards 1s;
  }

  .menuItem:nth-child(10) {
    animation: menuItemAnimation 1s ease-in-out forwards 1.1s;
  }

  /* Add more nth-child rules for additional menu items */
}

@media (max-width: 550px) {
  .content {
    height: calc(100vh - 230px);
  }
}

@media (max-width: 360px) {
  .content {
    height: calc(100vh - 200px);
  }
}
