
/* .page_transition-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  
  .page_transition-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page_transition-exit {
    opacity: 1;
  }
  
  .page_transition-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
    */

  .page_transition-enter {
    opacity: 0;
  }
  
  .page_transition-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .page_transition-exit {
    opacity: 1;
  }
  
  .page_transition-exit-active {
    opacity: 0 ;
    transition: opacity 300ms;
  }
 