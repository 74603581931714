.homePage {
    max-width: 100%;
    margin: 0 auto;
    height: calc(100vh - 155px);
    padding: 5%;
    text-align: center;
    overflow: auto;
  }
  
  .title {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .categoryGrid {
    all: unset;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    /* height: calc(100vh - 210px); */
    /* grid-auto-rows: min-content; */
    /* gap: 60px; */
    /* place-items: center; */
    /* overflow: auto; */

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust as needed */
    gap: 10px; /* Gap between items */
    /* align-items: center; */
    text-align: center;
    /* padding: 16px; */
    padding-bottom: 20px;
  }

  .categoryLink {
    /* position: relative;
    display: block;
    overflow: hidden; */
    flex: 0 1 calc(25% - 10px); /* 25% width with gap */
    max-width: calc(25% - 10px); /* 25% width with gap */

    margin-bottom: 60px;

    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1280px) {
    .categoryLink {
      flex: 0 1 calc(20% - 10px); /* 50% width with gap */
      max-width: calc(50% - 10px); /* 50% width with gap */
    }
  }

  /* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .categoryLink {
    flex: 0 1 calc(50% - 10px); /* 50% width with gap */
    max-width: calc(50% - 10px); /* 50% width with gap */
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .categoryLink {
    flex: 1 1 100%; /* Full width */
    max-width: 100%; /* Full width */
  }
}

  /* .categoryGrid a{
    all: unset;
  } */
  
  .categoryCard {
    position: relative;
    
    width: 250px;
    max-width: 250px;
    height: 220px;
    
    
  }

  .categoryCardBody
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    border-radius: 0px;
    cursor: pointer;
    color:#666;
    box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.3);

    transition: all 0.3s ease-out;
    transform: rotateX(0deg) rotateY(0deg);
    perspective: 1000px;
    transform-style: preserve-3d;
  }

  .categoryCardBody:hover {
    /* background-color: #ffeed8; */
    /* color:#000000; */
    
  }



  .t_over {
    width: 33.333%;
    height: 33.333%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  
  .t_over:nth-child(1) {
    left: 0;
    top: 0;
  }
  .t_over:nth-child(2) {
    left: 33.333%;
    top: 0;
  }
  .t_over:nth-child(3) {
    left: 66.666%;
    top: 0;
  }
  .t_over:nth-child(4) {
    left: 0;
    top: 33.333%;
  }
  .t_over:nth-child(5) {
    left: 33.333%;
    top: 33.333%;
  }
  .t_over:nth-child(6) {
    left: 66.666%;
    top: 33.333%;
  }
  .t_over:nth-child(7) {
    left: 0;
    top: 66.666%;
  }
  .t_over:nth-child(8) {
    left: 33.333%;
    top: 66.666%;
  }
  .t_over:nth-child(9) {
    left: 66.666%;
    top: 66.666%;
  }
  .t_over:hover ~ .categoryCardBody {
    /* background-color: #ffeed8; */
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.6);
    color:#000000;
  }
  .t_over:nth-child(1):hover ~ .categoryCardBody {
    transform: rotateX(-10deg) rotateY(10deg);
    
  }
  .t_over:nth-child(2):hover ~ .categoryCardBody {
    transform: rotateX(-10deg) rotateY(0deg);
  }
  .t_over:nth-child(3):hover ~ .categoryCardBody {
    transform: rotateX(-10deg) rotateY(-10deg);
  }
  .t_over:nth-child(4):hover ~ .categoryCardBody {
    transform: rotateX(0deg) rotateY(10deg);
  }
  .t_over:nth-child(5):hover ~ .categoryCardBody {
    transform: rotateX(0deg) rotateY(0deg);
  }
  .t_over:nth-child(6):hover ~ .categoryCardBody {
    transform: rotateX(0deg) rotateY(-10deg);
  }
  .t_over:nth-child(7):hover ~ .categoryCardBody {
    transform: rotateX(10deg) rotateY(10deg);
  }
  .t_over:nth-child(8):hover ~ .categoryCardBody {
    transform: rotateX(10deg) rotateY(0deg);
  }
  .t_over:nth-child(9):hover ~ .categoryCardBody {
    transform: rotateX(10deg) rotateY(-10deg);
  }

  /* .categoryCard::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 40%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    z-index: -1;
    transition: opacity 0.3s;
  }
  
  .categoryCard:hover::before {
    opacity: 0.3;
  } */





  
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 160px;
    border: 1px solid #ccc;
    background-color: #fff;
    margin-top: 12px;

    background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateZ(4px);
  }
  
  .icon {
    width: 100%;
    height: 100%;
  }

  .icon_label_container{
    height: 45px;
    position: relative;
    bottom: 0px;
    display: flex;
    align-items: center;
    transform: translateZ(4px);
  }
  
  .label {
    font-size: 1.2rem;
    margin-top: 10px;
    font-weight: lighter;
    /* color: #333333; */
    width: 228px;
  }
  
  /* Transition styles */
.page-transition-enter {
    opacity: 0;
  }
  
  .page-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .page-transition-exit {
    opacity: 1;
  }
  
  .page-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }