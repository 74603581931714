

body {
  padding: 0px;
  margin: 0px;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #eee;

}

html, body{
  overflow: hidden;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
